<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.jornadas.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.jornadas.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.jornadas.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <xlsx-workbook v-if="excel && excel.length > 0 && excel[0].data.length > 0">
                  <xlsx-sheet
                    v-for="sheet in excel"
                    :key="sheet.name"
                    :collection="sheet.data"
                    :sheet-name="sheet.name"
                  />
                  <xlsx-download
                    :filename="excelName"
                  >
                    <v-btn
                      text
                      :disabled="excel.length == 0"
                    >
                      <v-icon>
                        mdi-file-excel-box
                      </v-icon>
                      &nbsp;
                      Exportar a Excel
                    </v-btn>
                  </xlsx-download>
                </xlsx-workbook>
                <v-spacer />
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-select
                  v-model="filters.selected.items"
                  :items="filters.data.items"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.sucursales.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                  @change="onSelectBranch"
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="filters.selected.torneos"
                  :items="filters.data.torneos"
                  item-text="nombre"
                  item-value="id"
                  :label="$t('app.torneos.name')"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  outlined
                  rounded
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :items-per-page="10"
            :search="filters.selected.query"
          >
            <template v-slot:item.activo="{ item }">
              {{ item.activo ? $t('app.general.yes') : $t('app.general.no') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openCedula(item)"
                    >
                      <v-icon>
                        mdi-text-box-check
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('app.general.journey_card') }}
                  </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openManageActions(item)"
                    >
                      <v-icon>
                        mdi-bullseye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('app.general.manage') }}
                    {{ $t('app.general.actions') }}
                  </span>
                </v-tooltip>

                <v-btn
                  icon
                  @click="openPagoArbitraje(item)"
                >
                  <v-icon>
                    mdi-cash
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <jornadas-add />
    <jornadas-edit />
    <jornadas-detail />
    <jornadas-delete />
    <jornadas-manage-actions />
    <jornadas-add-actions />
    <jornadas-action-delete />

    <pago-add />
  </v-container>
</template>

<script>
  import { XlsxDownload, XlsxSheet, XlsxWorkbook } from 'vue-xlsx'
  export default {
    name: 'Jornadas',
    components: {
      JornadasAdd: () => import('./JornadasAdd'),
      JornadasEdit: () => import('./JornadasEdit'),
      JornadasDetail: () => import('./JornadasDetail'),
      JornadasDelete: () => import('./JornadasDelete'),
      JornadasManageActions: () => import('./JornadaManageActions'),
      JornadasAddActions: () => import('./JornadasAddActions'),
      JornadasActionDelete: () => import('./JornadasActionDelete'),

      PagoAdd: () => import('../pagos/PagoAdd'),

      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.torneos.single'),
            value: 'torneo.nombre',
          },
          {
            text: this.$t('app.headers.local_team'),
            value: 'equipo_local.nombre',
          },
          {
            text: this.$t('app.headers.visiting_team'),
            value: 'equipo_visitante.nombre',
          },
          {
            text: this.$t('app.canchas.single'),
            value: 'cancha.nombre',
          },
          {
            text: this.$t('app.headers.date'),
            value: 'fecha',
          },
          {
            text: this.$t('app.headers.time'),
            value: 'hora',
          },
          {
            text: this.$t('app.headers.description'),
            value: 'descripcion',
          },
          {
            text: this.$t('app.headers.active'),
            value: 'activo',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
            torneos: [],
          },
          selected: {
            items: [],
            torneos: [],
            query: '',
          },
        },
        excel: [],
        excelName: this.$t('app.jornadas.title') + ' - ' + this.$moment().format('YYYYMMDDHHmmss') + '.xlsx',
        excelHiddenColumns: [
          'id',
        ],
      }
    },
    computed: {
      filteredItems () {
        let items = this.items

        if (this.filters.selected.items.length > 0) {
          items = this.items.filter(item => this.filters.selected.items.includes(item.torneo.sucursal_id))
        }

        if (this.filters.selected.torneos.length > 0) {
          items = items.filter(item => this.filters.selected.torneos.includes(item.torneo_id))
        }

        return items
      },
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.filters.data.items = await this.getBranches()
        await this.$http.get(route('v1/journey'))
          .then(response => {
            if (response.body.code === 200) {
              this.items = response.body.data
            } else {
              this.processError(response)
            }
          }, error => {
            this.processError(error)
          })

        this.excel = [
          {
            name: this.$t('app.general.report'),
            data: this.prepareForExcel(this.items, this.headers, this.excelHiddenColumns),
          },
        ]

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('jornadas-add')
      },
      openItemEdit (item) {
        EventBus.$emit('jornadas-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('jornadas-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('jornadas-delete', item)
      },
      openManageActions (item) {
        EventBus.$emit('jornadas-manage-actions', item)
      },
      async onSelectBranch () {
        this.toggleLoader()

        this.filters.data.torneos = await this.getTorneosSearch({
          filters: {
            sucursal_id: this.filters.selected.items,
          },
        })

        this.toggleLoader()
      },
      openPagoArbitraje (item) {
        EventBus.$emit('pago-add', {
          item: item,
          conceptoPagoId: parseInt(process.env.VUE_APP_PAGO_ARBITRAJE_JORNADA),
        })
      },
      openCedula (item) {
        window.open([process.env.VUE_APP_SITE_URL, 'cedula', item.id].join('/'), '_blank')
      },
    },
  }
</script>

<style scoped>

</style>
